<!--
* AccessDenied.vue
*
* Description: Provides a landing place
* for denied routes
*
-->
<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Access Denied</div>
          <div class="card-body">
            <div v-if="user" class="alert alert-danger" role="alert">
              Insufficient Permissions
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>
